import { MatchingMessageListRs } from '@domain/rs/match/message/MatchingMessageListRs';
import DateFormat from '@utils/date/format';
import DateUtil from '@utils/date/util';

export default class MatchingMessageListInfoVO {
  positionName: string; // 포지션명
  companySn: number;
  companyName: string; // 기업명
  logoImageUrl: string; // 로고 이미지 URL
  endDateTime: string | null; // 포지션 종료 시간
  deleteDateTime: string | null; // 포지션 삭제 시간
  closingDateTime: string | null; // 포지션 마감 시간

  openYn: boolean; // 포지션 오픈 여부
  openingDateTime: string; //포지션 시작 시간
  beforeOpeningYn: boolean; // 포지션 시작 여부
  deleteYn: boolean; // 포지션 삭제 여부
  positionEndYn: boolean; //포지션 종료 여부
  closingYn: boolean; //포지션 마감 여부

  constructor(rs: MatchingMessageListRs) {
    this.positionName = rs.positionName;
    this.companySn = rs.companySn;
    this.companyName = rs.companyName;
    this.logoImageUrl = rs.logoImageUrl;
    this.endDateTime = rs.endDateTime ? DateFormat.format(new Date(rs.endDateTime), 'yyyy.MM.dd HH:mm') : null;
    this.deleteDateTime = rs.deleteDateTime ? DateFormat.format(new Date(rs.deleteDateTime), 'yyyy.MM.dd HH:mm') : null;
    this.openYn = rs.openYn;
    this.openingDateTime = rs.openingDateTime;
    this.beforeOpeningYn = DateUtil.compare(new Date(), new Date(rs.openingDateTime)) === -1;
    this.deleteYn = rs.deleteYn;
    this.positionEndYn = rs.endDateTime ? DateUtil.isPastDate(new Date(rs.endDateTime)) : false;
    this.closingYn = rs.closingDateTime ? DateUtil.isPastDate(new Date(rs.closingDateTime)) : false;
    this.closingDateTime = rs.closingDateTime;
  }
}
