import { useState } from 'react';
import classnames from 'classnames/bind';
import styles from './radioInput.module.scss';
const cx = classnames.bind(styles);

interface IRefuseModalProps {
  label: string | React.ReactNode;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
  name: string;
  condition?: boolean;
  children?: React.ReactNode;
  className?: string;
}

const RadioInput = ({ label, value, onChange, checked, name, condition, children, className }: IRefuseModalProps) => {
  const [isMouseOver, setIsMouseOver] = useState(false);

  return (
    <label
      className={cx('label', className)}
      onMouseOver={() => setIsMouseOver(true)}
      onMouseLeave={() => setIsMouseOver(false)}
    >
      <div className={cx('inputArea')}>
        <input
          name={name}
          type="radio"
          className={cx('input', 'hidden')}
          value={value}
          checked={checked}
          onChange={onChange}
        />
        <span className={cx('mark', { isMouseOver })} />
        {label}
      </div>
      {condition && children}
    </label>
  );
};

export default RadioInput;
