import { AxiosResponse } from 'axios';
import { axios } from '@repository/RequestClient';
import { MatchNotificationRq } from '@domain/rq/match/MatchNotificationRq';
import { NotificationRs } from '@domain/rs/match/NotificationRs';

export default interface NotificationsRepo {
  // 알림 조회
  fetchNotifications(): Promise<AxiosResponse<NotificationRs>>;

  // 알림 확인
  fetchPutNotifications(rq: MatchNotificationRq): Promise<AxiosResponse<boolean>>;

  // 알림 삭제
  fetchDeleteNotifications(rq: MatchNotificationRq): Promise<AxiosResponse<boolean>>;

  // 알림 강화필요 확인
  fetchEnforceProfile(): Promise<AxiosResponse<boolean>>;
}

export class RemoteNotificationRepo implements NotificationsRepo {
  fetchNotifications() {
    return axios.get<NotificationRs>('/v3/notifications');
  }

  fetchPutNotifications(rq: MatchNotificationRq) {
    return axios.put<boolean>('/notifications', rq);
  }

  fetchDeleteNotifications(rq: MatchNotificationRq) {
    return axios.delete<boolean>('/notifications', {
      data: rq,
    });
  }

  fetchEnforceProfile() {
    return axios.get<boolean>('/notifications/enforce-profile');
  }
}
