import { isEmpty } from '@utils/common';

export default class ObjectUtil {
  //모든 key에 대한 value의 값이 empty인지 확인
  static isKeysValueAllEmpty<T extends object, K extends (keyof T)[]>(obj: T, keys: K): boolean {
    return keys.every((key) => isEmpty(obj[key]));
  }

  //객체 key값을 순서대로 sort
  static sortObjectKeys<T extends object>(obj: T): Record<keyof T, any> {
    const keys = Object.keys(obj).sort() as (keyof T)[];
    const sortedObj: Partial<Record<keyof T, any>> = {};
    for (const key of keys) {
      sortedObj[key] = obj[key];
    }
    return sortedObj as Record<keyof T, any>;
  }

  //객체를 그룹으로 묶는 함수
  static groupBy = (key: string) => (array: any[]) =>
    array.reduce((objectsByKeyValue: any, obj: any) => {
      const value = obj[key];
      objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
      return objectsByKeyValue;
    }, {});
}
