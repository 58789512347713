export enum CompanyInfoTitle {
  ceo = 'ceo',
  salary = 'salary',
  employee = 'employee',
  enterprise = 'enterprise',
  take = 'take',
  location = 'location',
  establish = 'establish',
  profit = 'profit',
  length = 'length',
  yearCount = 'yearCount',
}

export type CompanyInfoTitleType = keyof typeof CompanyInfoTitle;

export const CompanyInfoTitleTypeTextMap: Record<CompanyInfoTitleType, string> = {
  ceo: '대표자명',
  salary: '전체 종업원 평균 연봉',
  employee: '직원 수',
  enterprise: '기업 구분',
  take: '매출액',
  location: '위치',
  establish: '설립일',
  profit: '영업 이익',
  length: '평균 근속',
  yearCount: '2022 입사자 / 퇴사자',
};
