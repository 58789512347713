import { useCallback } from 'react';
import { useToast } from '@components/common/toast';
import { useMessageActions, useMessageStore } from '@stores/MessageStore';
import { useNotificationActions, useNotificationStore } from '@stores/NotificationStore';
import UrlUtil from '@utils/urlUtil';

const useMessageModal = () => {
  const { fetchMessagesList } = useMessageActions();
  const { setPositionInfo, setMessageModalVisible } = useNotificationActions();
  const isMessageModalVisible = useNotificationStore((state) => state.isMessageModalVisible);
  const Toast = useToast();

  const openMessageModal = useCallback(
    async (positionSn?: number | null) => {
      // 이미 모달이 열려있는 경우 return
      if (isMessageModalVisible) return;

      const sn = positionSn ?? 0;
      await fetchMessagesList(sn);
      const messagesInfo = getMessagesInfo();

      // 메세지 정보가 없는 경우
      if (!messagesInfo?.companyName) {
        UrlUtil.removeQueryStringAndReplace('sn');
        Toast({ type: 'error', iconType: 'info', content: '존재하지 않는 포지션입니다.' });
        return;
      }

      setPositionInfo({
        companySn: messagesInfo?.companySn ?? 0,
        companyName: messagesInfo?.companyName ?? '',
        logoImageUrl: messagesInfo?.logoImageUrl ?? '',
        positionSn: sn,
        positionName: messagesInfo?.positionName ?? '',
      });

      // URL에 sn 쿼리 파라미터가 있을 경우 제거
      UrlUtil.removeQueryStringAndReplace('sn');

      setMessageModalVisible(true);
    },
    [isMessageModalVisible, setPositionInfo, setMessageModalVisible, fetchMessagesList],
  );

  return { openMessageModal };
};

export default useMessageModal;

export const getMessagesInfo = () => useMessageStore.getState().messagesInfo;
