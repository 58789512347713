import { NotificationMessageDto, NotificationRs } from '@domain/rs/match/NotificationRs';
import { MatchingNotificationType } from '@domain/constant/notificationTypeCode';
import DateFormat from '@utils/date/format';

export class MatchNotificationVO {
  accessYn: boolean; // 확인여부
  companyName: string | null; // 기업명
  companySn: number | null; // 기업 sn
  createdDateTime: Date; // 알림 시간
  createdDateTimeText: string; // 알림 시간 텍스트(1분 전, 1시간 전, 1일 전)
  positionSn: number | null; // 포지션 sn
  positionName: string | null; // 포지션 제목
  notificationSn: number; // 알림 sn
  expirationDays: number | null; //만료 기간
  type: MatchingNotificationType; // 매칭 알림타입
  applySn: number | null; // 결과표 sn

  constructor(rs: NotificationMessageDto) {
    this.accessYn = rs.accessYn;
    this.companySn = rs.data.companySn ?? null;
    this.companyName = rs.data.companyName ?? null;
    this.positionSn = rs.data.positionSn ?? null;
    this.positionName = rs.data.positionName ?? null;
    this.notificationSn = rs.notificationSn;
    this.expirationDays = rs.data.expirationDays ?? null;
    this.type = rs.type;
    this.applySn = rs.data.applySn ?? null;
    this.createdDateTime = new Date(rs.createdDateTime);
    this.createdDateTimeText = DateFormat.formatNotificationTimeText(this.createdDateTime);
  }
}

export default class MatchNotificationsVO {
  unreadCount: number;
  notifications: MatchNotificationVO[];

  constructor(rs: NotificationRs) {
    this.unreadCount = rs.unreadCount;
    this.notifications = rs.notifications.map((notification) => new MatchNotificationVO(notification));
  }
}
