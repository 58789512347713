import React, { useEffect } from 'react';
import useLocationCoordinates from '@utils/hooks/useLocationCoordinates';
import classnames from 'classnames/bind';
import styles from './index.module.scss';
const cx = classnames.bind(styles);

declare global {
  interface Window {
    kakao: any;
  }
}

interface KakaoMapPosition {
  La: number;
  Ma: number;
}

interface KakaoMap {
  setCenter: (coords: KakaoMapPosition) => void;
}

interface KakaoMapProps {
  className?: string;
  address: string;
  handleError?: () => void;
}

const KakaoMap = ({ className, address, handleError }: KakaoMapProps) => {
  const coords = useLocationCoordinates(address, handleError);
  const { kakao } = window;

  const initMap = () => {
    const mapEl = document.getElementById('map');
    const options = {
      center: new kakao.maps.LatLng(127.1, 37.4),
      level: 3,
    };
    markMap(new kakao.maps.Map(mapEl, options));
  };

  const markMap = (map: KakaoMap) => {
    new kakao.maps.Marker({ map, position: coords });
    map.setCenter(coords as KakaoMapPosition);
  };

  useEffect(() => {
    if (!kakao || !coords) return;
    initMap();
  }, [address, coords]);

  return <div id={'map'} className={cx('mapArea', className)} />;
};

export default KakaoMap;
