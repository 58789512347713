import Cookies from 'js-cookie';
import { fetchMessagesList } from '@repository/match/messages/useMessage';
import { createStore } from './utils';
import MatchMessageVO from '@domain/vo/match/message/MatchMessageVO';
import MatchingMessageListInfoVO from '@domain/vo/match/message/MatchingMessageListInfoVO';
import ObjectUtil from '@utils/object/util';
import { queryClient } from '@utils/queryClient';

interface MessageState {
  messagesInfo: MatchingMessageListInfoVO | null;
  messages: MatchMessageVO[];
  groupedMessagesByDate: Record<string, MatchMessageVO[]> | null; // 날짜 순으로 정렬된 메세지
}

interface MessageActions {
  actions: {
    initMessages: () => void;
    fetchMessagesList: (positionSn: number) => Promise<void>;
  };
}

export const useMessageStore = createStore<MessageState & MessageActions>((set) => ({
  messagesInfo: null,
  messages: [],
  groupedMessagesByDate: null,

  actions: {
    initMessages: () => {
      set((state) => {
        state.messages = [];
        state.groupedMessagesByDate = null;
      });
    },

    fetchMessagesList: async (positionSn: number) => {
      //매칭 제안 받은 경우 userInfo refetch
      queryClient.refetchQueries([Cookies.get('accessToken'), 'userInfo']);
      const { data } = await fetchMessagesList(positionSn);
      set((state) => {
        state.messagesInfo = new MatchingMessageListInfoVO(data);
        state.messages = data.messages.map((message) => new MatchMessageVO(message));
        state.groupedMessagesByDate = ObjectUtil.groupBy('createdDateTime')(state.messages);
      });
    },
  },
}));

export const useMessageActions = () => useMessageStore((state) => state.actions);
