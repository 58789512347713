import { useEffect, useState } from 'react';

interface KakaoMapPosition {
  La: number;
  Ma: number;
}

interface AddressSearchResult {
  x: number;
  y: number;
  address: {
    address_name: string;
  };
}

type KakaoMapsServicesStatusType =
  | 'OK' //검색 결과 있음
  | 'ZERO_RESULT' // 정상적으로 응답 받았으나 검색 결과는 없음
  | 'ERROR'; //서버 응답에 문제가 있는 경우

const useLocationCoordinates = (address: string, handleError?: () => void) => {
  const [coords, setCoords] = useState<KakaoMapPosition | null>(null);

  useEffect(() => {
    if (!window.kakao) return;
    const { kakao } = window;
    const geocoder = new kakao.maps.services.Geocoder();
    geocoder.addressSearch(address, (result: AddressSearchResult[], status: KakaoMapsServicesStatusType) => {
      switch (status) {
        case 'OK':
          const coords: KakaoMapPosition = new kakao.maps.LatLng(result[0].y, result[0].x);
          setCoords(coords);
          break;
        case 'ERROR':
        case 'ZERO_RESULT':
          handleError?.();
          break;
        default:
          break;
      }
    });
  }, [address]);

  return coords;
};

export default useLocationCoordinates;
