import { EventSourcePolyfill, EventSourcePolyfillInit, Event } from 'event-source-polyfill';
import { baseURL } from '@utils/common';

class EventSource<T> {
  private eventStream: EventSourcePolyfill | null = null;
  constructor({
    pathname = 'sse/subscribe',
    options = {},
    eventType,
    callback,
  }: {
    pathname?: string; // sse 엔드포인트
    options?: EventSourcePolyfillInit; // sse 옵션
    eventType: string; // 이벤트 타입 (jobda:v1:notification 등)
    callback: (data: T) => void; // 이벤트 발생 시 콜백함수
  }) {
    this.close();
    const base = baseURL.endsWith('/') ? baseURL.slice(0, -1) : baseURL;
    const path = pathname.startsWith('/') ? pathname.slice(1) : pathname;
    this.eventStream = new EventSourcePolyfill(`${base}/${path}`, {
      ...options,
      heartbeatTimeout: options.heartbeatTimeout ?? 600000,
    });
    this.eventStream.addEventListener(eventType, (e) => {
      if (EventSource.hasData(e)) {
        try {
          const data: T = JSON.parse(e.data);
          callback(data);
        } catch (error) {
          console.error(`Failed to parse SSE data: ${e.data}`, error);
        }
      }
    });

    // 페이지 언로드 시 SSE 연결 종료
    window.addEventListener('beforeunload', () => {
      this.close();
    });
  }

  private static hasData(e: Event): e is Event & { data: string } {
    return e.hasOwnProperty('data');
  }

  getEventStream() {
    return this.eventStream;
  }

  close() {
    if (this.eventStream) {
      this.eventStream.close();
      this.eventStream = null;
    }
  }
}

export default EventSource;
