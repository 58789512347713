/* eslint-disable react/display-name */
import React, { ChangeEvent, ChangeEventHandler, forwardRef, Fragment, ReactElement, ReactNode } from 'react';
import { ToastPopupProps, useToast } from '@components/common/toast';
import { useDownloadFile, useUploadFile } from '@repository/file/useFiles';
import { useUploadProfileFile } from '@repository/profiles/useProfile';

export type UploadFileStatus = 'uploading' | 'done' | 'error' | 'removed';

export interface UploadFile {
  fileName: string;
  fileUid: string;
  status: UploadFileStatus;
}

export interface ProfileUploadFile {
  fileUid: string;
  fileName: string;
  fileSize?: number;
  fileUrl?: string;
  status: UploadFileStatus;
}

export interface ProfileUploadFile {}

interface UploadItemProps {
  file: UploadFile;
}

const UploadItem = ({ file }: UploadItemProps) => {
  return (
    <div>
      {file.fileName}
      {file.fileUid}
    </div>
  );
};

export type ItemRender = (
  originNode: ReactElement,
  file: UploadFile,
  fileList: UploadFile[],
  actions: {
    download: () => void;
    remove: () => void;
  },
) => ReactNode;

export type BeforeUpload = (
  file: File,
  files: File[],
  fileCount: number,
  showToast: (options: ToastPopupProps) => void,
  accepts?: string[],
) => boolean;

/**
 * @param beforeUpload 파일을 실제로 업로드하기 전에 업로드 가능 여부 전달해주는 함수
 */
interface UploadProps {
  labelClassName?: string;
  name: string;
  value: UploadFile[];
  notice?: ReactNode;
  multiple?: boolean;
  accepts?: string[];
  onChange?: (files: UploadFile[] | ProfileUploadFile[]) => void;
  onClick?: (e: React.MouseEvent) => void;
  beforeUpload?: BeforeUpload;
  itemRender?: ItemRender;
  children?: ReactNode;
  authYn?: boolean;
  disabled?: boolean;
  type?: 'DEFAULT' | 'PROFILE';
}

const Upload = forwardRef<HTMLInputElement, UploadProps>(
  (
    {
      labelClassName,
      name,
      value,
      notice,
      multiple,
      accepts,
      onClick,
      onChange,
      beforeUpload,
      itemRender,
      children,
      authYn,
      disabled,
      type,
    },
    ref,
  ) => {
    const Toast = useToast();
    const { mutateAsync: uploadFile } = useUploadFile();
    const { mutateAsync: uploadProfileFile } = useUploadProfileFile();
    const { mutateAsync: downloadFile } = useDownloadFile();

    const parseFilesFromChangeEvent = (e: ChangeEvent<HTMLInputElement>) => {
      const { files } = e.target;
      const arrayParsedFiles = files ? Array.from(files) : [];
      return arrayParsedFiles;
    };

    const onUploadFiles = (files: File[]) => {
      const filteredFiles = files.filter((file) => {
        const uploadable = beforeUpload ? beforeUpload(file, files, value.length + files.length, Toast, accepts) : true;
        return uploadable;
      });

      const uploadedFiles = filteredFiles.map(async (file) => {
        if (type === 'DEFAULT') return await uploadFile({ file, authYn });
        else return await uploadProfileFile(file);
      });

      return Promise.all(uploadedFiles);
    };

    const handleChange: ChangeEventHandler<HTMLInputElement> = async (e) => {
      const files = parseFilesFromChangeEvent(e);
      const uploadFiles = await onUploadFiles(files);
      const nextValue = multiple ? [...value, ...uploadFiles] : uploadFiles;
      onChange?.(nextValue);
    };

    const onDownloadFile = async (file: UploadFile) => {
      if (file.status === 'error') return;
      await downloadFile(file);
    };

    const onRemoveFile = (index: number) => {
      const nextFiles = value.filter((_, i) => i !== index);
      onChange?.(nextFiles);
    };

    const uploadItemEls = value.map((file, i, files) => {
      const originNode = <UploadItem file={file} key={file.fileUid} />;
      if (!itemRender) return originNode;

      const handleDownloadFile = () => onDownloadFile(file);
      const handleRemoveFile = () => onRemoveFile(i);
      return (
        <Fragment key={file.fileUid}>
          {itemRender(originNode, file, files, {
            download: handleDownloadFile,
            remove: handleRemoveFile,
          })}
        </Fragment>
      );
    });

    const accept = accepts?.join(',');

    return (
      <>
        <label className={labelClassName} onClick={onClick}>
          <input
            style={{ display: 'none' }}
            name={name}
            type="file"
            onChange={handleChange}
            multiple={multiple}
            accept={accept}
            value="" //항상 onchange 이벤트 발생하시 위한 용도
            ref={ref}
            disabled={disabled}
          />
          {children}
        </label>
        {notice}
        {uploadItemEls}
      </>
    );
  },
);

export default Upload;
