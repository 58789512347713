import { useMutation, useQuery } from 'react-query';
import { RemoteNotificationRepo } from '@repository/notifications/NotificationsRepo';
import { MatchNotificationRq } from '@domain/rq/match/MatchNotificationRq';
import MatchNotificationsVO from '@domain/vo/match/MatchNotificationVO';

const remoteNotificationRepo = new RemoteNotificationRepo();

const keys = ['notifications'];

export const fetchNotifications = async () => {
  const { data } = await remoteNotificationRepo.fetchNotifications();
  return new MatchNotificationsVO(data);
};

export const fetchPutNotifications = async (rq: MatchNotificationRq) => {
  const { data } = await remoteNotificationRepo.fetchPutNotifications(rq);
  return data;
};

export const fetchDeleteNotifications = async (rq: MatchNotificationRq) => {
  const { data } = await remoteNotificationRepo.fetchDeleteNotifications(rq);
  return data;
};

export const useNotifications = () => {
  return useQuery([...keys], fetchNotifications);
};

export const usePutNotification = () => {
  return useMutation((rq: MatchNotificationRq) => fetchPutNotifications(rq));
};

export const useDeleteNotification = () => {
  return useMutation((rq: MatchNotificationRq) => fetchDeleteNotifications(rq));
};

export const useEnforceProfile = () => {
  return useQuery([...keys], async () => {
    const { data } = await remoteNotificationRepo.fetchEnforceProfile();
    return data;
  });
};
