// prettier-ignore
export const docAllowableExtArr = ['.doc', '.docx', '.hwp', '.hwpx', '.pdf', '.ppt', '.pptx', '.rtf', '.txt', '.xls', '.xlsx'];
export const videoVoiceAllowableExtArr = ['.avi', '.m4v', '.mov', '.mp3', '.mp4', '.wmv', 'webm'];

// prettier-ignore
export const imageAllowableExtArr = ['.tif', '.tiff', '.png', '.gif', '.jpg', '.jpeg', '.jfif', '.bmp', '.raw', '.psd', '.ai'];
export const zipAllowableExtArr = ['.egg', '.zip', '.alz', '.rar', '.7z'];
export const allFileAllowedExtensions = [
  ...docAllowableExtArr,
  ...videoVoiceAllowableExtArr,
  ...imageAllowableExtArr,
  ...zipAllowableExtArr,
];

export const profileAllowedExtensions = ['.ppt', '.pptx', '.pdf'];
export const profileImageAllowedExtensions = ['.jpg', '.png', '.JPG', '.PNG'];
