import {
  AttachFileDto,
  CommandTypeCode,
  InterviewLocationTypeCode,
  MatchingTalkApplyMessageDto,
  MatchingTalkMessageDto,
  MatchTalkMessageOptionDto,
  MessageTypeCode,
  SenderTypeCode,
} from '@domain/rs/match/message/MatchingMessageListRs';
import DateFormat from '@utils/date/format';
import DateUtil from '@utils/date/util';

export default class MatchMessageVO {
  sn: number;
  command: CommandTypeCode | null; // 매칭 알림타입
  createdDayTime: string; // 생성 시간
  createdDateTime: string; // 생성 일시
  decision: MatchTalkMessageOptionDto | null; // 메시지 선택지
  decisionDateTime: string | null; // 사용자 답변일
  decisionDueDateTime: string | null; // 사용자 답변 마감일
  decisionDueDateTimeValue: string | null;
  decisionDueDateTimeOriginal: string | null;
  decisionMatchingDueDateTimeValue: string | null;
  isEndDecisionDateTime: boolean;
  interviewLocation: string | null; // 인터뷰 장소
  interviewLocationType: InterviewLocationTypeCode | null; // 메시지 유형
  message: string; // 메시지 내용
  messageType: MessageTypeCode; // 메시지 타입
  options: MatchTalkMessageOptionDto[]; // 옵션 선택지
  sender: SenderTypeCode; // 발신자 종류
  title: string; // 메시지 제목
  companyAttachFiles: AttachFileDto[]; // 파일 리스트
  userAttachFiles: AttachFileDto[]; // 유저 파일 리스트
  submitApplies: MatchingTalkApplyMessageDto[];
  sendDateTime: string;

  constructor(rs: MatchingTalkMessageDto) {
    this.sn = rs.sn;
    this.command = rs.command;
    this.createdDayTime = DateFormat.formatAmPmText(new Date(rs.sendDateTime));
    this.createdDateTime = this.getYearFormat(rs.sendDateTime);
    this.decision = rs.decision;
    this.decisionDateTime = rs.decisionDateTime ? this.getDueDateTimeFormat(rs.decisionDateTime) : null;
    this.decisionDueDateTime = rs.decisionDueDateTime
      ? `답변 유효기간: ${this.getDueDateTimeFormat(rs.decisionDueDateTime)}`
      : null;
    this.decisionDueDateTimeValue = rs.decisionDueDateTime ? this.getDueDateTimeFormat(rs.decisionDueDateTime) : null;
    this.decisionDueDateTimeOriginal = rs.decisionDueDateTime;
    this.decisionMatchingDueDateTimeValue = rs.decisionDueDateTime
      ? this.getYearFormat(rs.decisionDueDateTime, 'yyyy년 MM월 dd일')
      : null;
    this.isEndDecisionDateTime = rs.decisionDueDateTime
      ? DateUtil.isPastDate(new Date(rs?.decisionDueDateTime), new Date())
      : false;
    this.interviewLocation = rs.interviewLocation;
    this.interviewLocationType = rs.interviewLocationType;
    this.message = rs.message;
    this.messageType = rs.messageType;
    this.options = rs.options;
    this.sender = rs.sender;
    this.title = rs.title;
    this.companyAttachFiles = rs.companyAttachFiles;
    this.userAttachFiles = rs.userAttachFiles;
    this.submitApplies = rs.submitApplies;
    this.sendDateTime = rs.sendDateTime;
  }

  // eg) 2021년 12.24 (금)
  private getYearFormat(createdDateTime: string, format = 'yyyy.MM.dd (week)') {
    return DateFormat.format(new Date(createdDateTime), format);
  }

  // eg) 2021-12-24 오전 11:30
  private getDueDateTimeFormat(createdDateTime: string) {
    return `${this.getYearFormat(createdDateTime, 'yyyy-MM-dd')} ${DateFormat.formatAmPmText(
      new Date(createdDateTime),
    )}`;
  }
}
