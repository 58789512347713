import { useQuery } from 'react-query';
import { RemoteMatchMessageRepo } from './MatchMessageRepo';

const messageRepo = new RemoteMatchMessageRepo();
const keys = ['messages'];

export const fetchMessages = async (messageSn: number) => {
  return await messageRepo.fetchMessages(messageSn);
};

export const fetchMessagesList = async (positionSn: number) => {
  return await messageRepo.fetchMessagesList(positionSn);
};

export function useMessages(messageSn: number) {
  return useQuery([...keys, messageSn], () => fetchMessages(messageSn));
}

export function useMessagesList(positionSn: number) {
  return useQuery([...keys, positionSn], () => fetchMessagesList(positionSn));
}
