import { EventSourcePolyfill } from 'event-source-polyfill';
import {
  fetchDeleteNotifications,
  fetchNotifications,
  fetchPutNotifications,
} from '@repository/notifications/useNotifications';
import { getAccessToken } from '@repository/AccessToken';
import { MATCHING_RESPONSE_STATUS_QUERY_KEY, MATCHING_STATUS_QUERY_KEY, MATCH_LIST_QUERY_KEY } from '@repository/match';
import { createStore } from './utils';
import { MatchNotificationRq } from '@domain/rq/match/MatchNotificationRq';
import { MatchNotificationVO } from '@domain/vo/match/MatchNotificationVO';
import RedisNotificationRs from '@domain/rs/sse/RedisNotificationRs';
import RedisMatchingRs from '@domain/rs/sse/RedisMatchingRs';
import EventSource from '@utils/eventSource';
import { queryClient } from '@utils/queryClient';
import { RouteUrl } from '@utils/routeUtil';
import DateFormat from '@utils/date/format';

export type IMatchingPositionInfoProps = Pick<
  MatchNotificationVO,
  'companySn' | 'companyName' | 'positionSn' | 'positionName'
> & { logoImageUrl: string };

interface NotificationState {
  count: number;
  notifications: MatchNotificationVO[];
  profileCompleteYn: boolean;
  notificationEventStream: EventSourcePolyfill | null;
  matchingEventStream: EventSourcePolyfill | null;
  positionInfo: IMatchingPositionInfoProps | null;
  isTooltipVisible: boolean;
  isLoginModalVisible: boolean;
  isMessageModalVisible: boolean;
  matchingCurrentPage: number;
}

interface NotificationActions {
  actions: {
    subscribeNotification: () => Promise<void>;
    unsubscribeNotification: () => Promise<void>;
    readNotifications: (sn: number[]) => Promise<void>;
    fetchNotifications: () => Promise<void>;
    confirmNotifications: (rq: MatchNotificationRq) => Promise<void>;
    removeNotifications: (rq: MatchNotificationRq) => Promise<void>;
    updateCreatedDateTime: () => void;
    setTooltipVisible: (isTooltipVisible: boolean) => void;
    setPositionInfo: (info: IMatchingPositionInfoProps) => void;
    setLoginModalVisible: (isLoginModalVisible: boolean) => void;
    setMessageModalVisible: (isMessageModalVisible: boolean) => void;
    setMatchingCurrentPage: (matchingCurrentPage: number) => void;
  };
}

export const useNotificationStore = createStore<NotificationState & NotificationActions>((set, get) => ({
  count: 0, // 안읽은 메세지 개수
  notifications: [], // 알림 목록
  profileCompleteYn: false,
  notificationEventStream: null, // 전체 알림 SSE
  matchingEventStream: null, // 매칭 알림 SSE, 매칭 현황 페이지 동기화를 위해 사용
  positionInfo: null,
  isTooltipVisible: false,
  isLoginModalVisible: false,
  isMessageModalVisible: false,
  matchingCurrentPage: 0, // 매칭 목록 현재 페이지 (SSE 관련 refetch를 위해 사용)

  actions: {
    subscribeNotification: async () => {
      const { notificationEventStream, matchingEventStream, actions } = get();
      const { fetchNotifications } = actions;

      const accessToken = getAccessToken();
      if (!accessToken) return;
      if (!notificationEventStream) {
        await fetchNotifications();

        // 전체 알림에 대한 SSE 연결
        const eventSource = new EventSource<RedisNotificationRs>({
          options: { headers: { Authorization: `Bearer ${accessToken}` } },
          eventType: 'jobda:v1:notification',
          callback: (data) => {
            const storeNotifications = get().notifications;
            const notifications = [new MatchNotificationVO(data.notification), ...storeNotifications];
            set({
              count: get().count + 1,
              notifications,
            });
          },
        });
        set({ notificationEventStream: eventSource.getEventStream() });
      }

      if (!matchingEventStream) {
        // 매칭 알림에 대한 SSE 연결
        const matchingEventSource = new EventSource<RedisMatchingRs>({
          options: { headers: { Authorization: `Bearer ${accessToken}` } },
          eventType: 'jobda:v1:matching',
          callback: (data) => {
            if (location.pathname !== RouteUrl.MATCH_APPLY) return;
            const page = get().matchingCurrentPage;
            const pageParams = {
              page,
              size: 5,
              tabType: data.statusType === 'OFFER' || data.statusType === 'OFFER_CLOSED' ? 'OFFER' : data.statusType,
            };
            queryClient.refetchQueries([MATCH_LIST_QUERY_KEY, pageParams]);
            queryClient.refetchQueries([MATCHING_STATUS_QUERY_KEY]);
            queryClient.refetchQueries([MATCHING_RESPONSE_STATUS_QUERY_KEY]);
          },
        });

        set({ matchingEventStream: matchingEventSource.getEventStream() });
      }
    },
    unsubscribeNotification: async () => {
      const { matchingEventStream, notificationEventStream } = get();
      if (notificationEventStream) {
        notificationEventStream.close();
      }

      if (matchingEventStream) {
        matchingEventStream.close();
      }

      set({ notificationEventStream: null, matchingEventStream: null });
    },
    fetchNotifications: async () => {
      const { unreadCount: count, notifications } = await fetchNotifications();
      set({ count, notifications });
    },
    confirmNotifications: async (rq: MatchNotificationRq) => {
      await fetchPutNotifications(rq);
    },
    readNotifications: async (sn: number[]) => {
      const { actions } = get();
      const { confirmNotifications } = actions;
      await confirmNotifications({ notificationSnList: sn });
    },
    removeNotifications: async (rq: MatchNotificationRq) => {
      await fetchDeleteNotifications(rq);
    },
    updateCreatedDateTime: () => {
      const notifications = get().notifications;
      const newNotifications = notifications.map((notification) => {
        notification.createdDateTimeText = DateFormat.formatNotificationTimeText(notification.createdDateTime);
        return notification;
      });
      set({ notifications: newNotifications });
    },
    setTooltipVisible: (isTooltipVisible: boolean) => {
      set({ isTooltipVisible });
    },
    setPositionInfo: (positionInfo: IMatchingPositionInfoProps) => {
      set({ positionInfo });
    },
    setLoginModalVisible: (isLoginModalVisible: boolean) => {
      set({ isLoginModalVisible });
    },
    setMessageModalVisible: (isMessageModalVisible: boolean) => {
      set({ isMessageModalVisible });
    },
    setMatchingCurrentPage: (matchingCurrentPage: number) => {
      set({ matchingCurrentPage });
    },
  },
}));

export const useNotificationActions = () => useNotificationStore((state) => state.actions);
