import { AxiosResponse } from 'axios';
import { axios } from '@repository/RequestClient';
import { MatchingMessageListRs, MatchingTalkMessageDto } from '@domain/rs/match/message/MatchingMessageListRs';

const matchMessagesPrefix = '/v2/match/messages';

export default interface MatchMessageRepo {
  // 매칭 메세지 단건 조회
  fetchMessages(messageSn: number): Promise<AxiosResponse<MatchingTalkMessageDto>>;

  // 매칭 메시지 조회
  fetchMessagesList(positionSn: number): Promise<AxiosResponse<MatchingMessageListRs>>;
}

export class RemoteMatchMessageRepo implements MatchMessageRepo {
  fetchMessages(messageSn: number) {
    return axios.get<MatchingTalkMessageDto>(`${matchMessagesPrefix}/${messageSn}`);
  }

  fetchMessagesList(positionSn: number) {
    return axios.get<MatchingMessageListRs>(matchMessagesPrefix, {
      params: {
        positionSn,
      },
    });
  }
}
